// Intersection Observer used for animations
const callback = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add(entry.target.getAttribute('data-animation'))
            entry.target.classList.remove('opacity-0');
            observer.unobserve(entry.target);
        }
    })
}

const myObserver = new IntersectionObserver(callback, {
    root: null,
    rootMargin: '-150px 0px -200px 0px',
    threshold: 0
})

const animatedElements = document.querySelectorAll('[data-animation]')

animatedElements.forEach((animatedElement) => {
    animatedElement.classList.add('opacity-0');
    myObserver.observe(animatedElement)
})
